import { Paper, Stack } from 'fbm-ui/lib/mui';
import SpeakerSelector from '../../Room/SpeakerSelector';
import { Box } from '@mui/material';
import { Button, Typography } from 'fbm-ui';
import SpeakerVolumeLevel from '../../Room/SpeakerVolumeLevel';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePlaySound from '../../Room/usePlaySound';
import { useAppSelector } from '../../../redux/hooks';

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const CHEAK_SATAE_INIT = 0;
const CHEAK_SATAE_FAIL = 2;
// const CHEAK_SATAE_UNAUTHORIZED = 3;

const Speaker = ({ onSuccess }) => {
  const isLocalAudioPermissionDenied = useAppSelector(
    (state) => state.room.isLocalAudioPermissionDenied
  );

  const { t } = useTranslation();
  const { play: playSound, audio } = usePlaySound();
  const [level, setLevel] = useState(0);

  const [state, setState] = useState(CHEAK_SATAE_INIT);

  const handleSuccess = useCallback(onSuccess, [onSuccess]);

  const handleFail = useCallback(() => {
    setState(CHEAK_SATAE_FAIL);
  }, []);

  const handleRetry = useCallback(() => {
    setState(CHEAK_SATAE_INIT);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (audio.current) {
        if (audio.current.paused) {
          setLevel(0);
        } else {
          setLevel(getRandomInt(3, 8));
        }
      }
    }, 100);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [audio]);

  return (
    <Stack spacing={1.5}>
      {!isLocalAudioPermissionDenied && (
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          color="#4caf50"
        >
          <img
            src="https://webcdn.fbmms.cn/assets/XUjW/XjPcEz-k07AcaR6Tr921q.png"
            alt=""
            style={{ width: 36, height: 36 }}
          />
          <SpeakerSelector width={348} />
        </Stack>
      )}

      <Box
        width={480}
        height={270}
        component={Paper}
        variant="outlined"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {state === CHEAK_SATAE_FAIL && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height={100}
          >
            <Typography>
              {t(
                'The current speaker is unavailable. Please use another device for the interview.'
              )}
            </Typography>
            <Button
              onClick={handleRetry}
              sx={{
                height: 32,
              }}
            >
              {t('Retest')}
            </Button>
          </Box>
        )}
        {state === CHEAK_SATAE_INIT && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height={200}
          >
            <Typography>
              {t('Can you hear the sound after clicking the play button?')}
            </Typography>
            <SpeakerVolumeLevel onClick={playSound} level={level} />
            <Stack
              spacing={1.5}
              direction="row"
              width={1}
              justifyContent="center"
              alignItems="center"
              bgcolor="white"
            >
              <Button
                variant="outlined"
                sx={{
                  height: 32,
                }}
                onClick={handleFail}
              >
                {t('Speaker No')}
              </Button>
              <Button
                onClick={handleSuccess}
                sx={{
                  height: 32,
                }}
              >
                {t('Speaker Yes')}
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default Speaker;
