import * as React from 'react';
import LoginAPI from '../../api/login';
import { showErrorMessage } from '../../utils/apiCheck';
import { Button, Typography } from 'fbm-ui';
import { useTranslation } from 'react-i18next';

const DEFAULT_COUNTDOWN_NUMBER = 60;
const CaptchaAppId = '198652052';

function CodeButton({ mobile, mobileAreaCode }) {
  const { t } = useTranslation();
  const [countdown, setCountdown] = React.useState(-1);

  const handleSubmit = React.useCallback(() => {
    try {
      //@ts-ignore
      const captcha = new TencentCaptcha(
        CaptchaAppId,
        (data) => {
          if (data.ret === 0) {
            LoginAPI.sendSmsCode(mobile, mobileAreaCode, data)
              .then(() => {
                setCountdown(DEFAULT_COUNTDOWN_NUMBER);
              })
              .catch(showErrorMessage);
          }
        },
        {
          userLanguage: sessionStorage.getItem('lang') || 'zh',
          needFeedBack: false,
        }
      );
      captcha.show();
    } catch (error) {
      console.error(error);
    }
  }, [mobile, mobileAreaCode]);

  React.useEffect(() => {
    if (countdown > -1) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  if (countdown > -1) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          minWidth: '106px',
          textAlign: 'right',
          marginTop: '12px !important',
        }}
      >
        {t('Retry after xs', { duration: countdown })}
      </Typography>
    );
  } else {
    return (
      <Button
        variant="contained"
        size="large"
        onClick={handleSubmit}
        sx={{ alignSelf: 'flex-start' }}
      >
        {t('Get code')}
      </Button>
    );
  }
}

export default CodeButton;
