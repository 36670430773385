import * as React from 'react';

import { useAppSelector } from '../../redux/hooks';
import { attachSinkId } from './helpers';

const url = `${process.env.PUBLIC_URL}/sounds/sound-testing.mp3`;
const urls = {
  zh: url,
  en: 'https://test-alicdn.fbmms.cn/48bb83af-d895-47c5-9547-014396be493b.mp3',
};

function usePlaySound() {
  const audio = React.useRef<HTMLAudioElement>();
  const selectedSpeakerId = useAppSelector(
    (state) => state.room.selectedSpeakerId
  );

  const play = React.useCallback(() => {
    audio.current?.pause();
    const lang = sessionStorage.getItem('lang') || 'zh';
    if (!audio.current) audio.current = new Audio(urls[lang]);
    if (selectedSpeakerId) attachSinkId(audio.current, selectedSpeakerId);
    audio.current?.play();
  }, [selectedSpeakerId]);

  return {
    play,
    audio,
  };
}

export default usePlaySound;
