import { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Room from './pages/Room';
import Interview from './pages/Interview';
import Typewriter from './pages/Typewriter';
import { Finish, Login as TypewriterLogin } from './modules/Typewriter';
import {
  Login,
  ProcessFinish,
  StudentLogin,
  LoginBindPosition,
  StudentBindPosition,
} from './modules/Login';
import BookingUrl from './pages/BookingUrl';
import OSSLogin from './pages/oss/Login';
import {
  AISimulateList,
  AISimulate,
  AIReport,
  AIEnterpriseSimulateList,
} from './modules/Interview';
import Language from './Language';
function App() {
  return (
    <Suspense fallback={null}>
      <Router>
        <Language>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/oss/login" element={<OSSLogin />} />
            <Route path="/interview/ai/:token" element={<Interview />} />
            <Route path="/c/:roomToken" element={<Room />} />
            <Route
              path="/t/:applicationToken/:interviewToken/:articleToken/:mode"
              element={<Typewriter />}
            />
            <Route path="/t/finish" element={<Finish />} />
            <Route
              path="/t/login/:applicationToken/:interviewToken"
              element={<TypewriterLogin />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/studentlogin" element={<StudentLogin />} />
            <Route
              path="/login/:interviewToken"
              element={<LoginBindPosition />}
            />
            <Route
              path="/studentlogin/:interviewToken"
              element={<StudentBindPosition />}
            />
            <Route
              path="/process/:applicationToken"
              element={<ProcessFinish />}
            />
            <Route
              path="/enterprise/simulate/:token/:only"
              element={<AIEnterpriseSimulateList />}
            />
            <Route path="/simulate/:type" element={<AISimulateList />} />
            <Route path="/simulate/:type/:token" element={<AISimulate />} />
            <Route path="/report/:type/:token" element={<AIReport />} />
            <Route path="/booking/:token" element={<BookingUrl />} />
          </Routes>
        </Language>
      </Router>
    </Suspense>
  );
}

export default App;
