import { SelectChangeEvent, Stack } from '@mui/material';
import { Select, Typography } from 'fbm-ui';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { roomActions } from './slice';
import { useTranslation } from 'react-i18next';

const CameraSelector = ({ width = 260 }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const camerasInfo = useAppSelector((state) => state.room.camerasInfo);
  const selectedCameraId = useAppSelector(
    (state) => state.room.selectedCameraId
  );

  const options = camerasInfo.map((option) => ({
    label: option.label,
    value: option.deviceId,
  }));
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const deviceId = event.target.value;
    dispatch(roomActions.setSelectedCameraId(deviceId));
  };

  if (camerasInfo.length > 0) {
    return (
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" sx={{ width: 84 }}>
          {t('Camera Test')}
        </Typography>
        <Select
          size="small"
          value={selectedCameraId}
          options={options}
          onChange={handleChange}
          sx={{ width }}
        />
      </Stack>
    );
  }

  return null;
};

export default CameraSelector;
