import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { Stack } from '@mui/material';
import { MicIcon } from 'fbm-ui';

import RoomModel from './RoomModel';
import { COLOR } from '../../utils/const';

function Block({ active }: { active: boolean }) {
  return (
    <div
      style={{
        width: '18px',
        height: '12px',
        borderRadius: '2px',
        backgroundColor: active ? '#4CAF50' : '#DEE0E3',
      }}
    />
  );
}

const blocks = Array.from(Array(10).keys());

function MicVolumeLevel({ active = true }) {
  const [level, setLevel] = React.useState(0);

  const volumeAnimation = React.useRef(0);
  useEffectOnce(() => {
    volumeAnimation.current = requestAnimationFrame(setVolumeWave);

    function setVolumeWave() {
      volumeAnimation.current = requestAnimationFrame(setVolumeWave);
      let level = RoomModel.localTracks?.audioTrack?.getVolumeLevel() || 0;
      level = Math.floor(level * 10);
      setLevel(level);
    }

    return () => {
      if (volumeAnimation.current) {
        cancelAnimationFrame(volumeAnimation.current);
      }
    };
  });

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      spacing={0.75}
    >
      <MicIcon style={{ color: COLOR.DEFAULT }} />
      {blocks.map((i) => (
        <Block active={active && level > i} />
      ))}
    </Stack>
  );
}

export default MicVolumeLevel;
