import Speaker from './Speaker';
import Microphone from './Microphone';
import Ready from './Ready';
import Camera from './Camera';

export const CHECK_STEP_INIT = 0;
export const CHECK_STEP_MIC = 1;
export const CHECK_STEP_SPEAKER = 2;
export const CHECK_STEP_CAMERA = 3;
export const CHECK_STEP_FINISH = 4;

const PermissionCheck = ({ step, onSuccess }) => {
  if (step === CHECK_STEP_SPEAKER) {
    return <Speaker onSuccess={() => onSuccess(CHECK_STEP_CAMERA)} />;
  } else if (step === CHECK_STEP_MIC) {
    return <Microphone onSuccess={() => onSuccess(CHECK_STEP_SPEAKER)} />;
  } else if (step === CHECK_STEP_CAMERA) {
    return <Camera />;
  }
  return <Ready />;
};

export default PermissionCheck;
