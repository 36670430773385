import { Box, Typography } from 'fbm-ui';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoginAPI from '../../api/login';

const Login = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');

  const redirectUrl = searchParams.get('redirect_url');

  const navigate = useNavigate();

  useEffect(() => {
    const paramsObj = Object.fromEntries([...searchParams.entries()]);
    LoginAPI.authenticationLogin(paramsObj)
      .then((res) => {
        const { token, departmentToken } = res;
        localStorage.setItem('id_token', token);
        navigate(`/enterprise/simulate/${departmentToken}/0`, {
          replace: true,
        });
      })
      .catch((e) => {
        setError(e.toString());
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      });
  }, [redirectUrl, navigate, searchParams]);

  return (
    <Box
      height={1}
      p={2}
      sx={{
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {error && <Typography>{error}</Typography>}
    </Box>
  );
};

export default Login;
