import { Paper, Stack } from 'fbm-ui/lib/mui';
import { Box } from '@mui/material';
import MicVolumeLevel from '../../Room/MicVolumeLevel';
import { Typography } from 'fbm-ui';
import MicSelector from '../../Room/MicSelector';
import { MicIcon } from 'fbm-ui';
import { useAppSelector } from '../../../redux/hooks';

const Ready = () => {
  const isLocalAudioPermissionDenied = useAppSelector(
    (state) => state.room.isLocalAudioPermissionDenied
  );

  return (
    <Stack spacing={1.5}>
      {!isLocalAudioPermissionDenied && (
        <Stack direction="row" spacing={1.5} alignItems="center">
          <MicIcon color="inherit" />
          <MicSelector width={358} />
        </Stack>
      )}
      <Box
        width={480}
        height={270}
        component={Paper}
        variant="outlined"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          height={200}
        >
          <Typography></Typography>
          <MicVolumeLevel active={false} />
          <Stack
            spacing={1.5}
            direction="row"
            width={1}
            justifyContent="center"
            alignItems="center"
            bgcolor="white"
          ></Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default Ready;
