import * as React from 'react';
import { Stack } from '@mui/material';

function Block({ active }: { active: boolean }) {
  return (
    <div
      style={{
        width: '18px',
        height: '12px',
        borderRadius: '2px',
        backgroundColor: active ? '#4CAF50' : '#DEE0E3',
      }}
    />
  );
}

const blocks = Array.from(Array(10).keys());

function SpeakerVolumeLevel({ active = true, onClick = undefined, level = 0 }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      spacing={0.75}
    >
      <img
        src={
          level > 0
            ? 'https://webcdn.fbmms.cn/assets/3znO/DomfD26XdTomMYLn8pr-J.png'
            : 'https://webcdn.fbmms.cn/assets/J6nq/JZdRdCK7G4rKXrVjVLrIs.png'
        }
        alt=""
        style={{ width: 48, height: 48, cursor: 'pointer' }}
        onClick={onClick}
      />
      {blocks.map((i) => (
        <Block active={active && level > i} />
      ))}
    </Stack>
  );
}

export default SpeakerVolumeLevel;
