import React, { useMemo, useCallback, useRef } from 'react';
import { Message, Box } from 'fbm-ui';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useAppSelector } from '../../redux/hooks';

type PusherProps = {
  liveUrl?: string;
  onPusherError?: (err: any) => void;
};

const Pusher = forwardRef(
  ({ liveUrl = '', onPusherError }: PusherProps, ref) => {
    const selectedCameraId = useAppSelector(
      (state) => state.room.selectedCameraId
    );
    const selectedMicId = useAppSelector((state) => state.room.selectedMicId);

    const videoStreamIdRef = useRef(null);
    const audioStreamIdRef = useRef(null);
    // 创建推流实例
    // @ts-ignore
    const pushClient = useMemo(() => new TXLivePusher(), []);

    const handlePusherError = useCallback(
      (err) => {
        if ([10000, 10002, 10004, 10008, 10006].includes(err.errorCode)) {
          Message.error('测试过程中必须开启摄像头');
          onPusherError?.(err);
        } else {
          Message.error(err.message);
        }
        console.log(err);
      },
      [onPusherError]
    );
    // 监听错误事件
    pushClient.setObserver({
      // 推流连接状态
      onPushStatusUpdate: function (status, message) {
        console.log(status, message);
      },
      // 推流统计数据
      onStatisticsUpdate: function () {
        // console.log('video fps is ' + data.video.framesPerSecond);
      },
      onWarning: function (code, message, data) {
        const WARNING_CODE = {
          CAMERA_INTERRUPTED: -1005, // 摄像头中断事件
          MICROPHONE_INTERRUPTED: -1006, // 麦克风中断事件
        };
        const deviceManager = pushClient.getDeviceManager();

        if (code === WARNING_CODE.CAMERA_INTERRUPTED) {
          // 摄像头中断，查找剩余可用摄像头
          deviceManager.getDevicesList('video').then(function (devices) {
            if (devices.length > 0 && devices[0].deviceId) {
              // 切换摄像头设备
              deviceManager.switchCamera(devices[0].deviceId, data.streamId);
              console.log('摄像头采集异常中断，自动切换');
            } else {
              // 结束推流
              if (pushClient.isPushing()) {
                pushClient.stopPush();
                console.log('摄像头采集异常中断，推流结束');
              }
              // 停止采集
              pushClient.stopCamera();
              console.log('停止采集摄像头');
            }
          });
        } else if (code === WARNING_CODE.MICROPHONE_INTERRUPTED) {
          // 麦克风中断，查找剩余可用麦克风
          deviceManager.getDevicesList('audio').then(function (devices) {
            if (devices.length > 0 && devices[0].deviceId) {
              // 切换麦克风设备
              deviceManager.switchMicrophone(
                devices[0].deviceId,
                data.streamId
              );
              console.log('麦克风采集异常中断，自动切换');
            } else {
              // 结束推流
              if (pushClient.isPushing()) {
                pushClient.stopPush();
                console.log('麦克风采集异常中断，推流结束');
              }
              // 停止采集
              pushClient.stopMicrophone();
              console.log('停止采集麦克风');
            }
          });
        }
      },
    });

    useEffect(() => {
      if (!liveUrl) return;

      pushClient.setRenderView('videoContainer') as HTMLVideoElement;

      pushClient.videoView.muted = true;

      // 设置视频质量
      pushClient.setVideoQuality('720p');
      // 设置音频质量
      pushClient.setAudioQuality('standard');
      // 自定义设置帧率
      pushClient.setProperty('setVideoFPS', 25);
      // 启用回声消除
      pushClient.setProperty('enableAudioAEC', true);
      // 启用噪声抑制
      pushClient.setProperty('enableAudioANS', true);

      Promise.all([pushClient.startCamera(), pushClient.startMicrophone()])
        .then(([videoStreamId, audioStreamId]) => {
          videoStreamIdRef.current = videoStreamId;
          audioStreamIdRef.current = audioStreamId;
          console.log(videoStreamId, audioStreamId);
        })
        .catch(handlePusherError);

      return () => {
        pushClient.stopMicrophone();
        pushClient.stopCamera();
        pushClient.stopPush();
      };
    }, [handlePusherError, liveUrl, pushClient]);

    useEffect(() => {
      return () => {
        console.log('puhser destroy');
        pushClient?.destroy();
      };
    }, [pushClient]);

    useImperativeHandle(ref, () => ({
      stopPush: () => {
        pushClient.stopMicrophone();
        pushClient.stopCamera();
        pushClient.stopPush();
      },
      startPush: () => {
        pushClient
          .startPush(liveUrl)
          .then(console.log)
          .catch(handlePusherError);
      },
      toggleCamera: () => {
        if (videoStreamIdRef.current) {
          videoStreamIdRef.current = null;
          pushClient.stopCamera();
        } else {
          pushClient
            .startCamera()
            .then((videoStreamId) => {
              videoStreamIdRef.current = videoStreamId;
            })
            .catch(handlePusherError);
        }
      },
      toggleMic: () => {
        console.log(audioStreamIdRef.current, 'audioStreamIdRef.current');
        if (audioStreamIdRef.current) {
          audioStreamIdRef.current = null;
          pushClient.stopMicrophone();
        } else {
          pushClient
            .startMicrophone()
            .then((audioStreamId) => {
              audioStreamIdRef.current = audioStreamId;
            })
            .catch(handlePusherError);
        }
      },
      getDevicesList: () => {
        const deviceManager = pushClient.getDeviceManager();
        return deviceManager.getDevicesList();
      },
    }));

    useEffect(() => {
      if (selectedCameraId && selectedCameraId !== 'default') {
        const deviceManager = pushClient.getDeviceManager();
        deviceManager.switchCamera(selectedCameraId);
      }
    }, [selectedCameraId, pushClient]);

    useEffect(() => {
      if (selectedMicId && selectedMicId !== 'default') {
        const deviceManager = pushClient.getDeviceManager();
        deviceManager.switchMicrophone(selectedMicId);
      }
    }, [selectedMicId, pushClient]);

    return (
      <Box id="videoContainer" sx={{ width: '100%', height: '100%' }}></Box>
    );
  }
);

export default React.memo(Pusher);
