import { Paper, Stack } from 'fbm-ui/lib/mui';
import { useAppSelector } from '../../../redux/hooks';
import MicSelector from '../../Room/MicSelector';
import { Box } from '@mui/material';
import { Button, Chip, Typography } from 'fbm-ui';
import MicVolumeLevel from '../../Room/MicVolumeLevel';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MicIcon } from 'fbm-ui';
import RoomModel from '../../Room/RoomModel';

function generateRandomSixDigitNumber() {
  const min = 100000; // 最小的 6 位数
  const max = 999999; // 最大的 6 位数
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const CHEAK_SATAE_INIT = 0;
const CHEAK_SATAE_FAIL = 2;
const CHEAK_SATAE_UNAUTHORIZED = 3;

const Microphone = ({ onSuccess }) => {
  const windowRef = useRef([]);
  const isLocalAudioPermissionDenied = useAppSelector(
    (state) => state.room.isLocalAudioPermissionDenied
  );
  const selectedMicId = useAppSelector((state) => state.room.selectedMicId);
  const { t } = useTranslation();

  const [state, setState] = useState(CHEAK_SATAE_INIT);
  const handleSuccess = useCallback(() => {
    if (windowRef.current.length > 8) {
      onSuccess?.();
    } else {
      setState(CHEAK_SATAE_FAIL);
    }
  }, [onSuccess]);

  const handleRetry = useCallback(() => {
    setState(CHEAK_SATAE_INIT);
  }, []);

  useEffect(() => {
    isLocalAudioPermissionDenied
      ? setState(CHEAK_SATAE_UNAUTHORIZED)
      : setState(CHEAK_SATAE_INIT);
  }, [isLocalAudioPermissionDenied]);

  useEffect(() => {
    windowRef.current = [];
  }, [selectedMicId]);

  useEffect(() => {
    const timer = setInterval(() => {
      const level = RoomModel.localTracks?.audioTrack?.getVolumeLevel() || 0;
      if (level > 0.6) {
        windowRef.current.push(level);
      }
    }, 100);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
      windowRef.current = [];
    };
  }, []);

  return (
    <Stack spacing={1.5}>
      {!isLocalAudioPermissionDenied && (
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          color="#4caf50"
        >
          <MicIcon color="inherit" />
          <MicSelector width={358} />
        </Stack>
      )}
      <Box
        width={480}
        height={270}
        component={Paper}
        variant="outlined"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="white"
      >
        {state === CHEAK_SATAE_FAIL && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height={100}
            padding={2}
          >
            <Typography variant="body2">
              {t(
                'The current microphone is unavailable. Please use another device for the interview.'
              )}
            </Typography>
            <Button
              onClick={handleRetry}
              sx={{
                height: 32,
              }}
            >
              {t('Retest')}
            </Button>
          </Box>
        )}
        {state === CHEAK_SATAE_UNAUTHORIZED && (
          <Box
            height={1}
            width={1}
            display="flex"
            flexDirection="column"
            p={1.5}
          >
            <Stack direction="row" spacing={0.5}>
              <Typography variant="body2"> {t('Microphone Test')}</Typography>
              {isLocalAudioPermissionDenied && (
                <Chip
                  color="warning"
                  variant="filled"
                  label={t('Unauthorized')}
                  sx={{
                    backgroundColor: '#F5C441',
                    '&:hover': {
                      backgroundColor: '#F5C441',
                    },
                  }}
                ></Chip>
              )}
            </Stack>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexGrow={1}
            >
              <Typography variant="body2">
                {t(
                  'Microphone permissions not obtained. Please grant access in system preferences.'
                )}
              </Typography>
            </Box>
          </Box>
        )}
        {state === CHEAK_SATAE_INIT && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height={200}
          >
            <Typography variant="body2">
              {t('Please read aloud the following numbers.')}
            </Typography>
            <Typography variant="h5" fontWeight={500}>
              {generateRandomSixDigitNumber()}
            </Typography>
            <MicVolumeLevel />
            <Stack
              spacing={1.5}
              direction="row"
              width={1}
              justifyContent="center"
              alignItems="center"
              bgcolor="white"
            >
              <Button
                onClick={handleSuccess}
                // onClick={handleFail}
                sx={{
                  height: 32,
                }}
              >
                {t('Finished')}
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default Microphone;
