import * as React from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useEffectOnce } from 'usehooks-ts';
import { Button, Text, toast } from '@avocadoui/components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { loginActions } from './slice';
import { DEFAULT_COUNTDOWN_NUMBER } from './const';
import useRoomToken from '../Room/useRoomToken';
import useMutationSendCodeToMobile from '../Room/useMutationSendCodeToMobile';
import useMutationSendCodeToEmail from '../Room/useMutationSendCodeToEmail';
import useLatestRef from '../../hooks/useLatestRef';

const CaptchaAppId = '198652052';

const countdownSelector = (state: RootState) => state.login.countdown;
const isCountingSelector = createSelector(
  countdownSelector,
  (countdown) => countdown < DEFAULT_COUNTDOWN_NUMBER
);

function CodeButton() {
  const roomToken = useRoomToken();
  const countdown = useAppSelector(countdownSelector);
  const isCounting = useAppSelector(isCountingSelector);
  const dispatch = useAppDispatch();
  const counter = React.useRef<ReturnType<typeof setInterval>>();
  const { mode, mobile, mobileAreaCode, email } = useAppSelector(
    (state) => state.login
  );
  const [disabled, setDisabled] = React.useState(false);

  const { mutate: sendCodeToMobile } = useMutationSendCodeToMobile();
  const { mutate: sendCodeToEmail } = useMutationSendCodeToEmail();

  const handleCount = () => {
    dispatch(loginActions.count());
    counter.current = setInterval(() => {
      dispatch(loginActions.count());
    }, 1000);
  };

  const handleSubmit = async () => {
    try {
      setDisabled(true);
      if (mode === 0) {
        //@ts-ignore
        const captcha = new TencentCaptcha(
          CaptchaAppId,
          (data) => {
            if (data.ret === 0) {
              sendCodeToMobile({ mobile, mobileAreaCode, ext: data });
              handleCount();
            }
          },
          {
            userLanguage: sessionStorage.getItem('lang') || 'zh',
            needFeedBack: false,
          }
        );
        captcha.show();
      } else if (mode === 1) {
        sendCodeToEmail({ email, roomToken });
        handleCount();
      }
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setDisabled(false);
    }
  };

  const handleSubmitRef = useLatestRef(handleSubmit);
  useEffectOnce(() => {
    handleSubmitRef.current?.();
  });

  const isFirstCount = React.useRef(true);
  React.useEffect(() => {
    if (
      !isFirstCount.current &&
      countdown === DEFAULT_COUNTDOWN_NUMBER &&
      counter.current
    ) {
      clearInterval(counter.current);
      isFirstCount.current = true;
    }
    isFirstCount.current = false;
  }, [countdown]);

  useEffectOnce(() => {
    return () => {
      counter.current && clearInterval(counter.current);
      dispatch(loginActions.resetCountdown());
    };
  });

  if (isCounting) {
    return (
      <Text
        variant="body2"
        color="text.secondary"
        sx={{ minWidth: '106px', textAlign: 'right', marginTop: '12px' }}
      >
        {countdown + 1}秒后重新发送
      </Text>
    );
  } else {
    return (
      <Button
        variant="contained"
        size="large"
        onClick={handleSubmit}
        disabled={disabled}
        sx={{ marginTop: '4px' }}
      >
        重新发送
      </Button>
    );
  }
}

export default CodeButton;
