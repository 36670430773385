import { Stack } from 'fbm-ui/lib/mui';
import { Avatar, Box, Typography, Popactions } from 'fbm-ui';
import { Paper } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { useCallback, useEffect, useState } from 'react';
import LoginAPI from '../../api/login';
import useReminder from './useReminder';
import { canMobileLogin } from './constant';
import BackTitle from './BackTitle';
import useRTC from '../Room/useRTC';
import { useTranslation } from 'react-i18next';
import { IS_QIANYU } from '../../oem';

const redirectToLogin = (navigate) => {
  navigate(
    `/${canMobileLogin() ? 'login' : 'studentlogin'}?redirect=${
      window.location.pathname
    }`
  );
};

function Layout({ children, showPosition = false }) {
  const { t } = useTranslation();
  useReminder();
  const navigate = useNavigate();

  const { leave } = useRTC();

  const { pathname = '' } = useLocation();

  const [user, setUser] = useState({
    avatar: '',
    name: '',
  });

  const { type = 'list' } = useParams();

  const room = useAppSelector((state) => state.interview);
  const positionName = room?.nextProcess.position?.name || '';

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment === null) {
      return;
    }
    navigate(`/simulate/${newAlignment}`);
  };
  const handleLogin = useCallback(() => redirectToLogin(navigate), [navigate]);

  useEffect(() => {
    LoginAPI.getCndidateInfo('').then(setUser).catch(console.error);
  }, []);

  const makeActions = (navigate, pathname) => {
    const actions = [
      {
        text: t('Interview History'),
        onClick: () => {
          navigate('/simulate/record');
        },
      },
      {
        text: t('Sign out'),
        onClick: () => {
          localStorage.clear();
          if (pathname.startsWith('/enterprise/simulate')) {
            window.location.reload();
          } else {
            redirectToLogin(navigate);
          }
        },
      },
    ].filter((v) => (IS_QIANYU ? v.text !== t('Interview History') : true));
    return actions;
  };

  const actions = makeActions(navigate, pathname);

  useEffect(() => {
    if (
      ['/enterprise/simulate', '/interview/ai'].every(
        (v) => !pathname.startsWith(v)
      )
    ) {
      leave();
    }
  }, [leave, pathname]);

  return (
    <Stack width="100vw" height="100vh">
      <Box
        flexShrink={0}
        component={Paper}
        width={1}
        height={60}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2.5}
      >
        {!showPosition && (
          <Stack display="flex" alignItems="center" direction="row" spacing={2}>
            {room.enterprise?.logo && (
              <img
                src={room.enterprise?.logo}
                alt="logo"
                style={{ height: 32, maxWidth: 160, objectFit: 'contain' }}
              />
            )}
            <Typography variant="body1" fontWeight="medium">
              {room.enterprise?.name}
            </Typography>
          </Stack>
        )}
        {showPosition && (
          <Stack display="flex" alignItems="center" direction="row" spacing={2}>
            <BackTitle title={positionName} />
          </Stack>
        )}
        {['/enterprise/simulate', '/interview/ai'].every(
          (v) => !pathname.startsWith(v)
        ) && (
          <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{
              justifySelf: 'center',
              height: 30,
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              '& .Mui-selected': {
                backgroundColor: '#4caf50 !important',
                color: 'white !important',
              },
            }}
          >
            <ToggleButton value="list">{t('Jobs')}</ToggleButton>
            <ToggleButton value="record">{t('Interview History')}</ToggleButton>
          </ToggleButtonGroup>
        )}
        <Stack
          display="flex"
          alignItems="center"
          justifyContent="center"
          direction="row"
          sx={{
            width: 102,
            height: 36,
            borderRadius: '18px',
            backgroundColor: 'rgba(240, 240, 240, 1)',
          }}
        >
          {/* @ts-ignore */}
          {!user.name && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{
                width: 102,
                height: 36,
              }}
            >
              <Avatar size={'small'} src={user?.avatar}>
                H
              </Avatar>
              <Typography
                minWidth={45}
                flexShrink={0}
                variant="body2"
                fontWeight="medium"
                sx={{ cursor: 'pointer' }}
                onClick={handleLogin}
              >
                {t('Sign in')}
              </Typography>
            </Stack>
          )}
          {user.name && (
            <Popactions actions={actions}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                sx={{
                  width: 102,
                  height: 36,
                }}
              >
                <Avatar size={'small'} src={user?.avatar}>
                  H
                </Avatar>
                <Typography
                  width={45}
                  flexShrink={0}
                  variant="body2"
                  fontWeight="medium"
                  sx={{ cursor: 'pointer' }}
                >
                  {user.name || '未登录'}
                </Typography>
              </Stack>
            </Popactions>
          )}
        </Stack>
      </Box>
      <Stack
        width={1}
        height={1}
        display="flex"
        alignItems="center"
        py={pathname.startsWith('/interview/ai') ? 0 : 4}
        overflow="scroll"
        bgcolor="#F9FAF9"
      >
        {children}
      </Stack>
    </Stack>
  );
}

export default Layout;
