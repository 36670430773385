import Pusher from '../Pusher';

import { Paper, Stack } from 'fbm-ui/lib/mui';
import { useAppSelector } from '../../../redux/hooks';
import CameraSelector from '../../Room/CameraSelector';
import { Box } from '@mui/material';
import { Button, Typography, Chip, VideocamIcon } from 'fbm-ui';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CHEAK_SATAE_INIT = 0;
const CHEAK_SATAE_FAIL = 2;
const CHEAK_SATAE_UNAUTHORIZED = 3;

const Camera = () => {
  const isLocalVideoPermissionDenied = useAppSelector(
    (state) => state.room.isLocalVideoPermissionDenied
  );
  const { t } = useTranslation();

  const [state, setState] = useState(CHEAK_SATAE_INIT);

  const handleRetry = useCallback(() => {
    setState(CHEAK_SATAE_INIT);
  }, []);

  useEffect(() => {
    isLocalVideoPermissionDenied
      ? setState(CHEAK_SATAE_UNAUTHORIZED)
      : setState(CHEAK_SATAE_INIT);
  }, [isLocalVideoPermissionDenied]);

  return (
    <Stack spacing={1.5}>
      {!isLocalVideoPermissionDenied && (
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          color="#4caf50"
        >
          <VideocamIcon color="inherit" />
          <CameraSelector width={358} />
        </Stack>
      )}
      {!isLocalVideoPermissionDenied && (
        <Typography variant="body2" align="center">
          {t(
            'Proceed to the "Next" after confirming the camera is functioning properly.'
          )}
        </Typography>
      )}
      <Box
        width={476}
        height={268}
        component={Paper}
        variant="outlined"
        display="flex"
        justifyContent="center"
        alignItems="center"
        // bgcolor="black"
        borderRadius={1}
        overflow="hidden"
      >
        {state === CHEAK_SATAE_UNAUTHORIZED && (
          <Box
            height={1}
            width={1}
            display="flex"
            flexDirection="column"
            p={1.5}
          >
            <Stack direction="row" spacing={0.5}>
              <Typography variant="body2">{t('Camera Test')}</Typography>
              {isLocalVideoPermissionDenied && (
                <Chip
                  color="warning"
                  variant="filled"
                  label={t('Unauthorized')}
                  sx={{
                    backgroundColor: '#F5C441',
                    '&:hover': {
                      backgroundColor: '#F5C441',
                    },
                  }}
                ></Chip>
              )}
            </Stack>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexGrow={1}
            >
              <Typography variant="body2">
                未获取到摄像头权限，请在系统偏好设置中授权
              </Typography>
            </Box>
          </Box>
        )}
        {state === CHEAK_SATAE_FAIL && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height={100}
          >
            <Typography>未检测到声音，请更换设备或重新测试</Typography>
            <Typography>确认摄像头显示正常后进入【下-步】</Typography>
            <Button
              onClick={handleRetry}
              sx={{
                height: 32,
              }}
            >
              重新检测
            </Button>
          </Box>
        )}
        {state === CHEAK_SATAE_INIT && !isLocalVideoPermissionDenied && (
          <Pusher liveUrl="artc://" />
        )}
      </Box>
    </Stack>
  );
};

export default Camera;
